<template>
  <div class="border-b-[0.75px]">
    <button
      :aria-label="title"
      class="link uppercase font-bold w-full text-left flex justify-between items-center lg:text-2xl"
      @click="isActive = !isActive"
    >
      <span class="inline-block py-30">{{ title }}</span>
      <ResponsiveSpriteSymbol
        :name="isActive ? 'minus' : 'plus'"
        class="w-[14px] h-[14px] lg:w-20 lg:h-20"
      />
    </button>

    <Collapse :when="isActive">
      <div>
        <slot></slot>
      </div>
    </Collapse>
  </div>
</template>

<script lang="ts" setup>
import { Collapse } from 'vue-collapsed'

defineProps<{
  title: string
}>()

const isActive = ref(false)
</script>
