<template>
  <div class="slider" @click.stop.prevent @mousedown.stop.prevent>
    <Flicking
      ref="flicking"
      :options="sliderOptions"
      :plugins="plugins"
      @dragstart.native.stop="$event.preventDefault()"
      @changed="$emit('update:modelValue', $event.index)"
    >
      <slot></slot>

      <template #viewport>
        <button
          v-if="arrows"
          class="flicking-arrow-prev absolute w-30 md:w-40 h-30 md:h-40 z-10 bg-black rounded-full left-30 top-1/2 text-center -translate-y-1/2"
        >
          <SpriteSymbol
            name="chevron-left-white-bold"
            class="h-[16px] w-[12px] text-white"
          />
        </button>
        <button
          v-if="arrows"
          class="flicking-arrow-next absolute w-30 md:w-40 h-30 md:h-40 z-10 bg-black rounded-full right-30 top-1/2 text-center -translate-y-1/2"
        >
          <SpriteSymbol
            name="chevron-right-white-bold"
            class="h-[16px] w-[12px] text-white"
          />
        </button>
        <div v-if="pagination" class="flicking-pagination container"></div>
      </template>
    </Flicking>
  </div>
</template>

<script lang="ts" setup>
import Flicking from '@egjs/vue3-flicking'
import type { FlickingOptions } from '@egjs/vue3-flicking'
import { Arrow, Pagination } from '@egjs/flicking-plugins'

const flicking = ref<Flicking | null>(null)

const getFlicking = (): Flicking | null => flicking.value

defineExpose({
  getFlicking,
})

const props = withDefaults(
  defineProps<{
    pagination?: boolean
    arrows?: boolean
    options?: Partial<FlickingOptions>
    index?: number
    panelsPerView?: number
    modelValue?: number
  }>(),
  {
    panelsPerView: 1,
    options: () => ({}),
    modelValue: 0,
  },
)

defineEmits(['update:modelValue'])

const defaultOptions: Partial<FlickingOptions> = {
  align: 'prev',
  circular: false,
  bound: !props.pagination,
  defaultIndex: props.index || 0,
}

const plugins: any[] = []

onMounted(() => {
  if (props.pagination) {
    plugins.push(new Pagination({ type: 'bullet' }))
  }
  if (props.arrows) {
    plugins.push(new Arrow())
  }
})

const sliderOptions = computed(() => {
  return {
    panelsPerView: props.panelsPerView,
    ...defaultOptions,
    ...props.options,
  }
})
</script>

<style lang="postcss">
@import '@egjs/vue3-flicking/dist/flicking.css';
@import '@egjs/flicking-plugins/dist/pagination.css';

.slider {
  .flicking-viewport {
    @apply relative overflow-hidden md:overflow-visible;
    touch-action: auto !important;
  }

  .flicking-arrow-disabled {
    @apply hidden;
  }

  .block-plugin {
    @apply w-2/3 md:w-1/3 pr-25 mt-0;
  }

  .flicking-pagination {
    @apply w-auto bg-transparent left-0 bottom-40;
  }

  .flicking-pagination-bullet {
    @apply bg-white ring-black ring-1 w-12.5 h-12.5 first:ml-0;
    @apply mx-10;
  }

  .flicking-pagination-bullet-active {
    @apply bg-black;
  }
}

.flicking-arrow-next,
.flicking-arrow-prev {
  border: 3px solid white;
  svg {
    @apply block m-auto;
  }
}

.flicking-camera {
  z-index: initial !important;
}
</style>
