<template>
  <DrupalLink v-if="to" :to="to" v-slot="{ isExternal }">
    <slot :label="label" :is-external="isExternal">{{ label }}</slot>
  </DrupalLink>
</template>

<script lang="ts" setup>
import {
  FieldItemTypeLinkEntityFragment,
  FieldItemTypeLinkFragment,
} from '#graphql-operations'

const props = defineProps<{
  title?: FieldItemTypeLinkFragment['title']
  uri?: FieldItemTypeLinkFragment['uri']
}>()

const entity = computed<FieldItemTypeLinkEntityFragment | undefined>(() => {
  if (props.uri && 'entity' in props.uri) {
    return props.uri.entity
  }
})

const label = computed(() => props.title || entity.value?.title)
const to = computed(() => props.uri?.path)
</script>
