<template>
  <div
    class="structured-table"
    :class="{
      'has-mobile-style': hasMobileStyle,
      'is-sticky-left': !hasMobileStyle && sticky === 'left',
      'is-sticky-top': !hasMobileStyle && sticky === 'top',
      'has-equalized-widths': equalizeWidths,
    }"
  >
    <table>
      <thead v-if="thead.length">
        <tr v-for="(row, i) in thead" :key="'thead_' + i">
          <component
            :is="cell.tag"
            v-for="(cell, j) in row"
            :key="'thead_' + i + '_' + j"
            v-bind="cell.attributes"
          >
            <div v-html="cell.content" />
          </component>
        </tr>
      </thead>

      <tbody v-if="tbody.length">
        <tr v-for="(row, i) in tbody" :key="'tbody_' + i">
          <component
            :is="cell.tag"
            v-for="(cell, j) in row"
            :key="'tbody_' + i + '_' + j"
            v-bind="getCellAttributes(cell, j)"
          >
            <div v-html="cell.content" />
          </component>
        </tr>
      </tbody>

      <tfoot v-if="tfoot.length">
        <tr v-for="(row, i) in tbody" :key="'tfoot_' + i">
          <component
            :is="cell.tag"
            v-for="(cell, j) in row"
            :key="'tfoot_' + i + '_' + j"
            v-bind="cell.attributes"
          >
            <div v-html="cell.content" />
          </component>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<!--

To add a caption underneath the table, use this component within a figure:

<figure>
  <StructuredTable ... />
  <figcaption>
    Caption goes here
  </figcaption>
</figure>
-->

<script lang="ts" setup>
import type { StructuredTableCellFragment } from '#graphql-operations'

const props = defineProps<{
  mobileStyle: 'horizontal' | 'vertical'
  sticky: 'none' | 'left' | 'top'
  equalizeWidths?: boolean
  thead: StructuredTableCellFragment[][]
  tbody: StructuredTableCellFragment[][]
  tfoot: StructuredTableCellFragment[][]

  // This is not needed in this component, but it's part of the fragment.
  // We define it here so that v-bind can still be used when rendering this
  // component with GraphQL data.
  caption?: string
}>()

const hasMobileStyle = computed(() => {
  return props.mobileStyle === 'vertical'
})

function getCellAttributes(cell: StructuredTableCellFragment, index: number) {
  const attributes: Record<string, string> = cell.attributes || {}
  if (props.thead.length) {
    if (props.thead[0] !== undefined) {
      if (props.thead[0][index] !== undefined) {
        const label = (props.thead[0][index].content || '').trim()
        attributes['data-head-label'] = label.replace(/<[^>]*>?/gm, '')
      }
    }
  }
  return attributes
}
</script>

<style lang="postcss">
.structured-table {
  @screen md {
    .text-align-right {
      @apply text-right;
    }
  }
  table {
    @apply w-full text-xs md:text-sm lg:text-lg;
  }
  td {
    /* @apply tracking-none; */
    @screen md {
      @apply min-w-[150px];
    }
  }

  th,
  td {
    @apply p-0;
    > div {
      @apply p-10;
    }
    @apply text-left align-top;
  }
  thead {
    @apply text-black;
    th > div {
      @apply pb-5 border-b-2 border-b-black;
    }
    th {
      @apply align-bottom;
    }
  }

  tbody {
    @apply border-b;
    td,
    th {
      > div {
        @apply p-10;
      }
    }
  }
  td,
  th {
    > div {
      @apply first:pl-0;
    }
  }

  caption {
    @apply text-left font-bold mb-10 text-xl;
  }

  a {
    @apply underline hover:opacity-60 transition-opacity;
  }

  &.has-mobile-style {
    table {
      @apply until-md:border-t;
    }
    thead {
      @apply until-md:hidden;
    }
    tbody th,
    tbody tr,
    tbody td {
      @apply until-md:block;
    }
    td,
    th {
      @apply until-md:px-0;
    }

    tbody tr {
      @apply until-md:border-t;
    }

    td[data-head-label]:before {
      @apply until-md:content-[attr(data-head-label)] until-md:block until-md:text-xs until-md:text-black until-md:font-bold until-md:mt-20;
    }

    td {
      p {
        @apply not-last:mb-10;
      }
    }
  }

  &.is-sticky-left {
    @media screen and (max-width: theme('screens.md')) {
      @apply overflow-auto pb-10;
      th,
      td {
        @apply whitespace-nowrap;
      }

      th:first-child {
        @apply sticky left-0 bg-white pr-0;

        + td > div,
        + th > div {
          @apply pl-20;
        }
      }
    }
  }

  &.is-sticky-top {
    thead th {
      @apply sticky top-50 bg-white;
    }
  }

  &.has-equalized-widths {
    @screen md {
      table {
        table-layout: fixed;
      }
      th {
        @apply whitespace-nowrap;
      }
    }
  }
}
</style>
