<template>
  <blockquote
    v-if="isVisible"
    class="paragraph-quote"
    :class="[
      {
        container: !parentType,
        [marginClasses]: !parentType,
      },
      globalClasses,
    ]"
  >
    <p
      class="is-quote lg:max-w-prose"
      v-html="text"
      v-blokkli-editable:field_text
    />
    <footer
      v-if="quotee"
      class="mt-20 text-lg lg:text-2xl font-semibold"
      v-blokkli-editable:field_title
    >
      {{ quotee }}
    </footer>
  </blockquote>
</template>

<script lang="ts" setup>
const { parentType, options } = defineBlokkli({
  bundle: 'quote',
  globalOptions: ['region', 'marginTop'],
  editor: {
    previewWidth: 800,
    editTitle: (el) =>
      (el.querySelector('footer') || el.querySelector('p'))?.innerText,
  },
})

const { isVisible, marginClasses, globalClasses } =
  useMappedParagraphOptions(options)

defineProps<{
  text: string
  quotee?: string
}>()
</script>

<style lang="postcss">
.paragraph-quote {
  p {
    &:before {
      content: '«';
    }
    &:after {
      content: '»';
    }
  }
}
</style>
