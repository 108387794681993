<template>
  <div class="relative h-full grid grid-cols-12 gap-30">
    <!--todo: is this nice like this? (empty col)-->
    <MediaImage
      v-if="image"
      v-bind="image"
      :style-name="styleName"
      class="col-span-6 lg:col-span-5"
    />
    <div class="col-start-7 col-span-6 space-y-20 lg:space-y-40">
      <h2 v-if="title">{{ title }}</h2>
      <div class="content" v-html="body" />
      <DrupalLink
        v-if="url"
        :to="url"
        class="arrow-icon-link icon-link link mt-30"
        >{{ buttonText }}</DrupalLink
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { MediaImageFragment } from '#graphql-operations'

const props = withDefaults(
  defineProps<{
    title?: string
    url: string
    image?: MediaImageFragment
    styleName?: string
    buttonText?: string
    body?: string
  }>(),
  {
    styleName: 'content_fullwidth',
  },
)

const caption = computed(() => props.image?.caption)
</script>

<style lang="postcss"></style>
