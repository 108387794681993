<template>
  <div
    v-if="isVisible"
    class="container"
    :class="[marginClasses, globalClasses]"
  >
    <BlokkliField
      name="field_paragraphs"
      :list="paragraphs"
      class="grid gap-30 paragraph-grid"
      :class="{
        'md:grid-cols-2': options.columns === 'two',
        'md:grid-cols-3': options.columns === 'three',
        'md:grid-cols-2 lg:grid-cols-4': options.columns === 'four',
        'items-start': options.alignment === 'top',
        'items-center': options.alignment === 'center',
        'items-end': options.alignment === 'bottom',
      }"
    />
  </div>
</template>

<script lang="ts">
export default {
  name: 'ParagraphGrid',
}
</script>

<script lang="ts" setup>
import type { ParagraphGridFragment } from '#graphql-operations'

const { options } = defineBlokkli({
  bundle: 'grid',
  globalOptions: ['region', 'marginTop'],
  options: {
    columns: {
      type: 'radios',
      label: 'Columns',
      displayAs: 'grid',
      options: {
        two: { label: 'Two', columns: [1, 1] },
        three: { label: 'Three', columns: [1, 1, 1] },
        four: { label: 'Four', columns: [1, 1, 1, 1] },
      },
      default: 'three',
    },
    alignment: {
      type: 'radios',
      label: 'Alignment',
      options: {
        top: 'Top',
        center: 'Center',
        bottom: 'Bottom',
      },
      default: 'center',
    },
  },
  editor: {
    disableEdit: true,
  },
})

const { isVisible, marginClasses, globalClasses } =
  useMappedParagraphOptions(options)

defineProps<{
  paragraphs?: ParagraphGridFragment['paragraphs']
}>()

const imageStyle = computed(() => {
  switch (options.value.columns) {
    case 'two':
      return 'column_half'
    case 'three':
      return 'column_third'
  }

  return 'column_quarter'
})

provide('imageStyle', imageStyle)
</script>
