<template>
  <div v-if="isEditing || name" class="grid grid-cols-6 gap-20">
    <div class="col-span-2">
      <MediaImage v-if="photo" v-bind="photo" style-name="column_quarter" />
    </div>
    <div class="content col-span-4">
      <h4>{{ name }}</h4>
      <div class="person-content" v-html="body"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ParagraphPersonFragment } from '#build/graphql-operations'

const { isEditing } = defineBlokkli({
  bundle: 'person',
  editor: {
    editTitle: (el) => el.querySelector('h4')?.innerText,
  },
})

const props = defineProps<{
  person?: ParagraphPersonFragment['person']
}>()

const photo = computed(() => props.person?.photo)
const name = computed(() =>
  [props.person?.firstname, props.person?.lastname].filter(Boolean).join(' '),
)
const body = computed(() => props.person?.body)
</script>

<style lang="postcss">
.person-content {
  p,
  h2,
  h3 {
    @apply text-lg lg:text-xl;
  }
}
</style>
