
    import { defineAsyncComponent } from '#imports'
    
import BlokkliComponent_from_library_index from '/var/www/prod.exped.com/releases/202407300736/frontend/node_modules/blokkli-beta/dist/runtime/components/Blocks/FromLibrary/index.vue'
import BlokkliComponent_gallery_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Gallery/index.vue'
import BlokkliComponent_document_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Document/index.vue'
import BlokkliComponent_category_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Category/index.vue'
import BlokkliComponent_image_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Image/index.vue'
import BlokkliComponent_page_header_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/PageHeader/index.vue'
import BlokkliComponent_grid_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Grid/index.vue'
import BlokkliComponent_links_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Links/index.vue'
import BlokkliComponent_pg_teaser_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/PgTeaser/index.vue'
import BlokkliComponent_person_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Person/index.vue'
import BlokkliComponent_quote_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Quote/index.vue'
import BlokkliComponent_product_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Product/index.vue'
import BlokkliComponent_remote_video_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/RemoteVideo/index.vue'
import BlokkliComponent_product_section_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/ProductSection/index.vue'
import BlokkliComponent_slider_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Slider/index.vue'
import BlokkliComponent_text_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Text/index.vue'
import BlokkliComponent_table_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Table/index.vue'
import BlokkliComponent_title_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Title/index.vue'
import BlokkliComponent_two_columns_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/TwoColumns/index.vue'
import BlokkliComponent_widget_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Widget/index.vue'
import BlokkliComponent_three_columns_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/ThreeColumns/index.vue'
import BlokkliComponent_wrike_webform_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/WrikeWebform/index.vue'
import BlokkliComponent_blokkli_fragment_index from '/var/www/prod.exped.com/releases/202407300736/frontend/node_modules/blokkli-beta/dist/runtime/components/Blocks/Fragment/index.vue'
import BlokkliComponent_teaser_index from '/var/www/prod.exped.com/releases/202407300736/frontend/components/Paragraph/Teaser/index.vue'

const global: Record<string, any> = {
  block_from_library: BlokkliComponent_from_library_index,
  block_gallery: BlokkliComponent_gallery_index,
  block_document: BlokkliComponent_document_index,
  block_category: BlokkliComponent_category_index,
  block_image: BlokkliComponent_image_index,
  block_page_header: BlokkliComponent_page_header_index,
  block_grid: BlokkliComponent_grid_index,
  block_links: BlokkliComponent_links_index,
  block_pg_teaser: BlokkliComponent_pg_teaser_index,
  block_person: BlokkliComponent_person_index,
  block_quote: BlokkliComponent_quote_index,
  block_product: BlokkliComponent_product_index,
  block_remote_video: BlokkliComponent_remote_video_index,
  block_product_section: BlokkliComponent_product_section_index,
  block_slider: BlokkliComponent_slider_index,
  block_text: BlokkliComponent_text_index,
  block_table: BlokkliComponent_table_index,
  block_title: BlokkliComponent_title_index,
  block_two_columns: BlokkliComponent_two_columns_index,
  block_widget: BlokkliComponent_widget_index,
  block_three_columns: BlokkliComponent_three_columns_index,
  block_wrike_webform: BlokkliComponent_wrike_webform_index,
  block_blokkli_fragment: BlokkliComponent_blokkli_fragment_index,
  block_teaser: BlokkliComponent_teaser_index
}

    


const globalFragments: Record<string, any> = {
  
}


const chunks: Record<string, () => Promise<any>> = {
  rare: () => import('#blokkli/chunk-rare')
}

const chunkMapping: Record<string, string> = {}

const fragmentChunkMapping: Record<string, string> = {}

export function getBlokkliItemComponent(bundle: string, fieldListType?: string, parentBundle?: string): any {
  const forFieldListType = 'block_' + bundle + '__field_list_type_' + fieldListType
  if (global[forFieldListType]) {
    return global[forFieldListType]
  }
  if (parentBundle) {
    const forParentBundle = 'block_' + bundle + '__parent_block_' + parentBundle
    if (global[forParentBundle]) {
      return global[forParentBundle]
    }
  }
  const key = 'block_' + bundle
  if (global[key]) {
    return global[key]
  }
  const chunkName = chunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}

export function getBlokkliFragmentComponent(name: string): any {
  const key = 'fragment_' + name
  if (globalFragments[key]) {
    return globalFragments[key]
  }
  const chunkName = fragmentChunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}
