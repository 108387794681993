<template>
  <div
    class="container"
    :class="[globalClasses, marginClasses]"
    v-if="isVisible"
  >
    <h2 class="is-2" v-if="title" v-blokkli-editable:field_title>
      {{ title }}
    </h2>
    <div v-if="src" :class="{ 'pointer-events-none': isEditing }">
      <iframe
        v-resize="{ log: true, scrolling: true }"
        class="wrike-iframe"
        :src="src"
        frameborder="0"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import iframeResize from 'iframe-resizer/js/iframeResizer'
import { ParagraphWrikeWebformFragment } from '#build/graphql-operations'

const vResize = {
  mounted: (
    el: { addEventListener: (arg0: string, arg1: () => any) => void },
    { value = {} }: any,
  ) => {
    el.addEventListener('load', () => iframeResize(value, el))
  },
  unbind(el: { iFrameResizer: { removeListeners: () => void } }) {
    // @ts-ignore
    el.iFrameResizer.removeListeners()
  },
}
const { options, isEditing } = defineBlokkli({
  bundle: 'wrike_webform',
  globalOptions: ['region', 'marginTop'],

  editor: {
    noPreview: true,
  },
})

const { isVisible, globalClasses, marginClasses } =
  useMappedParagraphOptions(options)

const props = defineProps<{
  link?: ParagraphWrikeWebformFragment['link']
  title?: ParagraphWrikeWebformFragment['title']
}>()

const src = computed(() => props.link?.uri?.path)
</script>

<style lang="postcss">
.wrike-iframe {
  width: 100%;
  height: 1024px;
  border: none;
}
</style>
