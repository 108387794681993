/**
 * Return YouTube ID from URL.
 */
export function getYouTubeID(url: string): string | null {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[7].length === 11 ? match[7] : null
}

export function getVimeoID(providedUrl: string): string | null {
  const url = providedUrl || ''
  if (!url.includes('vimeo')) {
    return null
  }
  const matches = url.match(/\/(\d+)/) || []
  return matches?.[1] || null
}

export function getYouTubeThumbnail(id: string) {
  return `https://i3.ytimg.com/vi/${id}/maxresdefault.jpg`
}
