<template>
  <div
    v-if="isVisible"
    class="paragraph-text"
    :class="[
      {
        container: !parentType,
      },
      globalClasses,
      marginClasses,
    ]"
  >
    <div
      class="content max-w-prose"
      v-html="body"
      v-blokkli-editable:field_body="{ type: 'frame' }"
      @click.capture="onClick"
    />
  </div>
</template>

<script lang="ts" setup>
const { parentType, options, index } = defineBlokkli({
  bundle: 'text',
  globalOptions: ['region', 'marginTop'],
  editor: {
    previewWidth: 512,
    editTitle: (el) => el.querySelector('div')?.innerText,
  },
})

const { onClick } = useClickTriggerProxy()

const { isVisible, marginClasses, globalClasses } = useMappedParagraphOptions(
  options,
  index,
)

defineProps<{
  body: string
}>()
</script>
