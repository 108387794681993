<template>
  <div
    :class="[
      {
        [marginClasses]: !parentType,
      },
      globalClasses,
      { container: options.display !== 'fullwidth' && !parentType },
    ]"
  >
    <div v-if="!to && isVisible" class="paragraph-image">
      <MediaImage
        v-if="image"
        v-bind="image"
        :styleName="styleName"
        v-blokkli-droppable:field_media_reference
        :class="{
          'aspect-[3/2] object-cover': styleName === 'column_half_2_3',
        }"
      />
    </div>
    <DrupalLink v-else-if="to && isVisible" :to="to" class="paragraph-image">
      <MediaImage
        v-if="image"
        v-bind="image"
        :styleName="styleName"
        v-blokkli-droppable:field_media_reference
      />
    </DrupalLink>
  </div>
</template>

<script lang="ts" setup>
import type {
  FieldItemTypeLinkFragment,
  MediaImageFragment,
} from '#graphql-operations'

const { parentType, options } = defineBlokkli({
  bundle: 'image',
  globalOptions: ['region', 'marginTop'],
  options: {
    display: {
      type: 'radios',
      label: 'Display',
      default: 'fullwidth',
      options: {
        fullwidth: 'Fullwidth',
        contained: 'Contained',
      },
    },
  },
})

const { isVisible, globalClasses, marginClasses } =
  useMappedParagraphOptions(options)

const props = defineProps<{
  image?: MediaImageFragment
  link?: FieldItemTypeLinkFragment
}>()

/**
 * This can be provided by parent paragraphs to suggest an image style.
 */
const suggestedImageStyle = inject<ComputedRef<string> | null>(
  'imageStyle',
  null,
)

/**
 * The responsive image style name to use.
 */
const styleName = computed(() => {
  if (!parentType.value) {
    return 'content_fullwidth'
  }
  return suggestedImageStyle?.value || 'column_half'
})

const to = computed(() => {
  return props.link?.uri?.path
})
</script>

<script lang="ts">
export default {
  name: 'ParagraphImage',
}
</script>
