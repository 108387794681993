/**
 * Checks if the URL is external.
 */
export function isExternal(href = '', origin = '') {
  if (origin && href.startsWith(origin)) {
    return false
  }
  return /^(mailto:|tel:|\/media|http|www)/.test(href)
}

/**
 * Convert absolute URLs to relative.
 */
export function toRelativeUrl(url = '') {
  return url.replace(/^https?:\/\/[^/]*/gm, '')
}
