<template>
  <div
    v-if="isVisible"
    class="paragraph-category"
    :class="[{ container: !parentType }, globalClasses]"
  >
    <template v-if="category && url && category.name">
      <ProductTeaser
        v-if="options.display === 'teaser'"
        :title="category.name"
        :url="url"
        :image="category.teaserImage"
      />
      <TeaserBox
        v-else
        :title="category.name"
        :image="category.teaserImage"
        :url="url"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { ParagraphCategoryFragment } from '#graphql-operations'

const { parentType, options } = defineBlokkli({
  bundle: 'category',
  globalOptions: ['region'],
  options: {
    display: {
      type: 'radios',
      default: 'teaser',
      label: 'Display',
      options: {
        teaser: 'Teaser',
        box: 'Box',
      },
    },
  },
})

const { isVisible, globalClasses } = useMappedParagraphOptions(options)

const props = defineProps<{
  category?: ParagraphCategoryFragment['category']
}>()

const url = computed(() => props.category?.url?.path)
</script>

<style lang="postcss"></style>
