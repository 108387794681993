<template>
  <div
    v-if="video?.url && isVisible"
    class="paragraph-remote-video"
    :class="[
      {
        container: !parentType,
        [marginClasses]: !parentType,
      },
      globalClasses,
    ]"
  >
    <MediaRemoteVideo
      v-bind="video"
      :hide-overlay="options.hideOverlay"
      v-blokkli-droppable:field_media_reference
    />
  </div>
</template>

<script lang="ts" setup>
import { ParagraphRemoteVideoFragment } from '#graphql-operations'

const { options, parentType } = defineBlokkli({
  bundle: 'remote_video',
  globalOptions: ['region', 'marginTop'],
  options: {
    hideOverlay: {
      type: 'checkbox',
      label: 'Hide overlay',
      default: false,
    },
  },
})

const { isVisible, marginClasses, globalClasses } =
  useMappedParagraphOptions(options)

defineProps<{
  video?: ParagraphRemoteVideoFragment['video']
}>()
</script>

<style lang="postcss"></style>
