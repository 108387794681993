<template>
  <div
    class="container mb-25"
    v-if="isVisible"
    :class="[marginClasses, globalClasses]"
  >
    <div
      class="paragraph-two-columns grid gap-30 lg:grid-cols-12"
      :class="{
        'items-start': options.alignment === 'top',
        'items-center': options.alignment === 'center',
        'items-end': options.alignment === 'bottom',
      }"
    >
      <BlokkliField
        :list="paragraphs"
        name="field_paragraphs"
        :class="{
          'lg:col-span-6': options.columns === 'equal',
          'lg:col-span-8': options.columns === 'wideLeft',
          'lg:col-span-4': options.columns === 'wideRight',
        }"
      />
      <BlokkliField
        name="field_paragraphs_aside"
        :list="paragraphsAside"
        class="gap-30 paragraph-two-columns-aside"
        :class="{
          'order-first': options.reverse,
          'items-start': options.alignment === 'top',
          'items-center': options.alignment === 'center',
          'items-end': options.alignment === 'bottom',
          'lg:col-span-6': options.columns === 'equal',
          'lg:col-span-4': options.columns === 'wideLeft',
          'lg:col-span-8': options.columns === 'wideRight',
        }"
      />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'ParagrapTwoColumns',
}
</script>

<script lang="ts" setup>
import type { ParagraphTwoColumnsFragment } from '#graphql-operations'

const { options } = defineBlokkli({
  bundle: 'two_columns',
  globalOptions: ['marginTop', 'region'],
  options: {
    columns: {
      type: 'radios',
      label: 'Columns',
      displayAs: 'grid',
      options: {
        equal: { label: 'Equal', columns: [1, 1] },
        wideLeft: { label: 'Wide left', columns: [8, 3] },
        wideRight: { label: 'Wide right', columns: [3, 8] },
      },
      default: 'equal',
    },
    reverse: {
      type: 'checkbox',
      label: 'Reverse',
      default: false,
    },
    alignment: {
      type: 'radios',
      label: 'Alignment',
      options: {
        top: 'Top',
        center: 'Center',
        bottom: 'Bottom',
      },
      default: 'top',
    },
  },

  editor: {
    disableEdit: true,
  },
})

const { isVisible, marginClasses, globalClasses } =
  useMappedParagraphOptions(options)

defineProps<{
  paragraphs?: ParagraphTwoColumnsFragment['paragraphs']
  paragraphsAside?: ParagraphTwoColumnsFragment['paragraphsAside']
}>()

const imageStyle = computed(() => {
  switch (options.value.columns) {
    case 'equal':
      return 'teaser_half'
    case 'wideLeft':
      return 'teaser_half'
  }

  return 'teaser_half'
})

provide('imageStyle', imageStyle)
</script>

<style lang="postcss">
.paragraph-two-columns-aside {
  > * {
    flex: 1;
  }
}
</style>
