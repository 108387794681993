<template>
  <div class="relative">
    <div class="mx-40 lg:mx-100 space-y-20 relative">
      <div class="border-t-3 border-b-3 border-black relative">
        <MediaImage
          v-if="teaserImage"
          v-bind="teaserImage"
          style-name="product_teaser"
        />
        <div
          v-if="statusMapped.length"
          class="absolute top-0 bottom-0 left-0 flex flex-col justify-center"
        >
          <div class="flex flex-col items-start space-y-5">
            <div
              v-for="item in statusMapped"
              :key="item"
              class="badge uppercase"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>

      <!--
        NOTE: Not needed at the moment
        <p v-if="variationTitle" class="mt-10 text is-small">
          {{ variationTitle }}
        </p> -->
      <DrupalLink v-if="url" :to="url" class="btn">{{
        $texts('toProductButtonText', 'To the product')
      }}</DrupalLink>
    </div>
    <h2 class="absolute top-0 right-0 is-3 lg:text-3xl vertical-text">
      {{ title }}
    </h2>
  </div>
</template>

<script lang="ts" setup>
import { MediaImageFragment, ProductTeaserFragment } from '#graphql-operations'
import { falsy } from '~/helpers/type'

const props = defineProps<{
  title?: string
  variation?: ProductTeaserFragment['variation']
  status?: string[]
  image?: MediaImageFragment
  url?: string
}>()

const statusMapped = computed(
  () =>
    props.status ||
    props.variation?.statusTerms?.map((term) => term?.name).filter(falsy) ||
    [],
)

const url = computed<string | undefined>(
  () => props.url || props.variation?.url?.path,
)
const teaserImage = computed<MediaImageFragment | undefined>(
  () => props.image || props.variation?.images?.[0],
)
</script>
