import type { BlockOptionDefinition } from '#blokkli/types/blokkOptions'

type GlobalOptionsDefaults = {
  type: BlockOptionDefinition['type']
  default: any
}

export const globalOptionsDefaults: Record<string, GlobalOptionsDefaults> = {
  "marginTop": {
    "default": "xl",
    "type": "radios"
  },
  "region": {
    "default": [],
    "type": "checkboxes"
  }
} as const