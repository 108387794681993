<template>
  <div class="block-plugin relative">
    <iframe
      style="border: none; width: 100%; min-height: 800px"
      src="/iframe/locally"
    />
  </div>
</template>

<script lang="ts" setup></script>
