<template>
  <VuepalRokkaImage
    v-bind="image"
    :style-name="styleName"
    class="bg-gray-100"
  />
</template>

<script lang="ts" setup>
import type { MediaImageFragment } from '#graphql-operations'
import type { StyleName } from '#vuepal/rokka'
import type { VuepalRokkaImage } from '#components'

withDefaults(
  defineProps<{
    id?: string
    name?: string
    image?: MediaImageFragment['image']
    caption?: string
    copyright?: string
    langcode?: string
    styleName: StyleName
  }>(),
  {
    styleName: 'teaser_half',
  },
)
</script>

<script lang="ts">
export default {
  name: 'MediaImage',
}
</script>
